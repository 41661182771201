import { authenticate, initializeAuthentication, forgotPassword, frejaInitAuth, frejaAuthenticationResult } from '../api/authApi';
import types from './types';

function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

var actions = {
  [types.actions.LOGIN_FORM_SET_FIELD]: (context, payload) => {
    context.commit(types.mutations.LOGIN_FORM_SET_FIELD, payload);
  },
  [types.actions.LOGIN_FORM_SUBMITTED]: async (context) => {
    context.commit(types.mutations.LOGIN_FORM_SET_FIELD, { field: 'submitted', value: true });
    context.commit(types.mutations.SET_ERROR, undefined);
    if (!context.state.frejaToken && Object.values(context.getters[types.getters.LOGIN_VALIDATION]).length > 0) {
      return;
    }
    try {
      context.commit(types.mutations.SET_LOADING, 'Loggar in...');
      let results = await initializeAuthentication(context.state.loginForm, context.state.frejaToken);
      context.commit(types.mutations.SET_CLUBS, results);
      if (results.clubs.length === 1 && !results.firstLogin) {
        context.dispatch(types.actions.LOGIN);
      }
    } catch (ex) {
      context.commit(types.mutations.SET_ERROR, ex.message);
    }
  },
  [types.actions.LOGIN]: async (context) => {
    try {
      context.commit(types.mutations.SET_LOADING, 'Loggar in...');
      const { loginForm, clubForm, accessToken, frejaToken, isStarter } = context.state;
      await authenticate(loginForm, clubForm, accessToken, frejaToken);
      let redirectQuery = new URLSearchParams(window.location.search).get('redirectUri');
      if (isStarter) {
        context.commit(types.mutations.SET_FIELD, { field: 'isStarter', value: false });
        window.location.replace(window.location.origin + '/starter/');
        return;
      }
      if (redirectQuery && !redirectQuery.includes('/starter')) {
        if (context.state.clubChanged && redirectQuery.includes('#')) {
          redirectQuery = redirectQuery.split('#')[0];
        }
        const redirect = decodeURIComponent(redirectQuery);
        window.location.replace(redirect);
      } else {
        window.location.replace(window.location.origin + '/person/');
      }
    } catch (ex) {
      context.commit(types.mutations.SET_ERROR, ex.message);
    }
  },
  [types.actions.SELECT_CLUB]: (context, payload) => {
    context.commit(types.mutations.SELECT_CLUB, payload);
  },
  [types.actions.RESET_LOGIN]: (context) => {
    context.commit(types.mutations.RESET_LOGIN);
  },
  [types.actions.SET_LOADING]: (context, payload) => {
    context.commit(types.mutations.SET_LOADING, payload);
  },
  [types.actions.PASSWORD_FORM_SET_FIELD]: (context, payload) => {
    context.commit(types.mutations.PASSWORD_FORM_SET_FIELD, payload);
  },
  [types.actions.SEND_NEW_PASSWORD]: async (context) => {
    context.commit(types.mutations.PASSWORD_FORM_SET_FIELD, { field: 'submitted', value: true });
    if (Object.values(context.getters[types.getters.RESET_PASSWORD_VALIDATION]).length > 0) {
      return;
    }
    context.commit(types.mutations.SET_LOADING, 'Återställer lösenord...');
    try {
      await forgotPassword(context.state.passwordForm);
      context.commit(types.mutations.PASSWORD_FORM_SET_FIELD, { field: 'passwordSent', value: true });
      context.commit(types.mutations.SET_LOADING, undefined);
    } catch (ex) {
      context.commit(types.mutations.SET_ERROR, ex.message);
    }
  },
  [types.actions.RESET_MESSAGE]: (context) => {
    context.commit(types.mutations.RESET_MESSAGE);
    context.commit(types.mutations.RESET_LOGIN);
  },
  [types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(types.mutations.SET_FIELD, fieldValue);
  },
  [types.actions.FREJA_INIT_AUTHENTICATION]: async (context) => {
    context.commit(types.mutations.RESET_LOGIN_FORM);
    context.commit(types.mutations.SET_FIELD, { field: 'frejaLogin', value: true });
    const authRef = await frejaInitAuth();
    context.commit(types.mutations.SET_FIELD, { field: 'frejaAuthRef', value: authRef });
    const urlEncodedAuthRef = encodeURIComponent(authRef);
    const frejaEidAuhtenticationSchema = 'frejaeid://bindUserToTransaction?transactionReference=' + urlEncodedAuthRef;
    if (isMobile()) {
      //window.location = frejaEidAuhtenticationSchema + '&originAppScheme=' + window.location;
      window.location = frejaEidAuhtenticationSchema;
    }
    context.dispatch(types.actions.FREJA_AUTHENTICATION_RESULT);
  },
  [types.actions.FREJA_AUTHENTICATION_RESULT]: async (context, failover) => {
    setTimeout(async () => {
      try {
        if (!context.state.frejaAuthRef) {
          return;
        }
        const result = await frejaAuthenticationResult(context.state.frejaAuthRef);
        context.commit(types.mutations.SET_FIELD, { field: 'frejaStatus', value: result.status });
        if (result.status === 'APPROVED') {
          context.commit(types.mutations.SET_FIELD, { field: 'frejaToken', value: result.details });
          context.dispatch(types.actions.LOGIN_FORM_SUBMITTED);
        } else if (result.status === 'CANCELED' || result.status === 'RP_CANCELED') {
          context.commit(types.mutations.SET_FIELD, {
            field: 'error',
            value: 'Autentiseringen avbröts',
          });
        } else if (result.status === 'EXPIRED') {
          context.commit(types.mutations.SET_FIELD, {
            field: 'error',
            value: 'Autentiseringen har gått ut',
          });
        } else if (result.status === 'REJECTED') {
          context.commit(types.mutations.SET_FIELD, {
            field: 'error',
            value: 'Autentiseringen avslogs',
          });
        } else {
          context.dispatch(types.actions.FREJA_AUTHENTICATION_RESULT);
        }
      } catch (err) {
        const failoverAsNumber = failover ? failover : 0;
        if (failoverAsNumber < 5) {
          context.dispatch(types.actions.FREJA_AUTHENTICATION_RESULT, failoverAsNumber + 1);
        } else {
          context.commit(types.mutations.SET_FIELD, {
            field: 'error',
            value: err?.message ? `Ett fel har inträffat: ${err.message}` : 'Ett fel har inträffat!',
          });
        }
      }
    }, 1000 * 5);
  },
};

export default actions;
