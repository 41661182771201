<template>
  <div class="logo-atom">
    <img class="logo-atom__logo" src="../../../public/LOGO_SGF_FARG.svg" onerror="this.style.display='none'" />
  </div>
</template>

<script>
export default {
  name: 'LogoAtom',
};
</script>

<style scoped>
.logo-atom {
  position: fixed;
  top: 16px;
  left: 16px;
  padding: 16px;
}
.logo-atom__logo {
  min-height: 40px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo-atom__logo {
    min-height: 80px;
  }
}

@media (min-width: 992px) and (max-width: 2559px) {
  .logo-atom__logo {
    min-height: 80px;
  }
}

@media (min-width: 2560px) and (max-width: 3839px) {
  .logo-atom__logo {
    min-height: 80px;
  }
}

@media (min-width: 3840px) {
  .logo-atom__logo {
    min-height: 80px;
  }
}
</style>
