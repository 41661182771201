<template>
  <div class="layout-molecule">
    <LoaderAtom mode="fullscreen" v-if="loading" :header="loading" />
    <LogoAtom />
    <div class="login-container ace-scroll" v-if="!loading && !hasConfirmPaswordReset">
      <ContainerAtom class="login-container__content">
        <div class="ace-column">
          <h1>Välkommen till GIT!</h1>
          <p v-if="isFirstStep">Välj vart du vill logga in</p>
          <slot></slot>
        </div>
      </ContainerAtom>
    </div>
  </div>
</template>

<script>
import { LoaderAtom, ContainerAtom, ModalAtom, ButtonAtom } from '@sgf/go-ace-ui';

import { LogoAtom } from '../atoms';
export default {
  props: {
    loading: String,
    hasConfirmPaswordReset: Boolean,
    isFirstStep: Boolean,
  },
  components: {
    ContainerAtom,
    LoaderAtom,
    LogoAtom,
    ModalAtom,
    ButtonAtom,
  },
  emits: ['confirmPasswordReset'],
  methods: {
    onConfirmPasswordReset() {
      this.$emit('confirmPasswordReset');
    },
  },
};
</script>

<style scoped>
.layout-molecule {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}
.login-container {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 16px;
}

.login-container__content {
  width: 400px;
}

.login-container__content h1 {
  text-align: center;
  padding-bottom: 16px;
}

.login-container__content p {
  text-align: center;
}

@media (max-width: 768px) {
  .login-container {
    position: static;
    display: block;
  }
  html body .login-container__content {
    width: unset;
    margin: 16px;
  }
}
.layout-molecule :deep(.container-atom__box) {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
</style>
